#interactive video{
    width: 362px !important;
    height: 220px !important;
}
.viewport .imgBuffer{
    display: none;
}
.viewport .drawingBuffer{
    display: none;
}
