@font-face {
  font-family: 'NotoSans-Regular';
  src: url('../font/NotoSans-Regular.ttf');
}

@font-face {
  font-family: 'NotoSans-Italic';
  src: local('NotoSans-Italic'),
    url('../font/NotoSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-Light';
  src: url('../font/NotoSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-Bold';
  src: local('NotoSans-Bold'),
    url('../font/NotoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-SemiBold';
  src: local('NotoSans-SemiBold'),
    url('../font/NotoSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-Medium';
  src: local('NotoSans-Medium'),
    url('../font/NotoSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-Light';
  src: local('NotoSans-Light'),
    url('../font/NotoSans-Light.ttf') format('truetype');
}

body {
  font-family: 'NotoSans-Regular' !important;
}

.loader-white {
  color: #ffffff !important;
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 100%;
  font-family: 'NotoSans-Regular';
  color: #004d64;
  font-size: 24px;
  font-weight: 400;
}

.not-found-tex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  font-family: 'NotoSans-Regular';
  color: #004d64;
  font-size: 24px;
  font-weight: 400;
}

.margin-b-10 {
  margin-bottom: 10px;
}

.calendar-text {
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 1 !important;
  display: -webkit-box !important;
  overflow: hidden !important;
  word-break: break-all !important;
  -webkit-box-orient: vertical !important;
  white-space: normal !important;
}
